import iconBag from "@/pages/Orders/assets/icons/bag.svg"
import iconDelivery from "@/pages/Orders/assets/icons/delivery.svg"
import iconFalse from "@/pages/Orders/assets/icons/false.svg"
import iconTrue from "@/pages/Orders/assets/icons/true.svg"
import iconReset from "@/pages/Orders/assets/icons/reset.svg"
import iconResetWhite from "@/pages/Orders/assets/icons/reset-white.svg"
import iconExclamationMark from "@/pages/Orders/assets/icons/exclamation-mark.svg"
import iconClock from "@/pages/Orders/assets/icons/clock.svg"
import iconBagFull from "@/pages/Orders/assets/icons/bag-full.svg"
import iconQueue from "@/pages/Orders/assets/icons/queue.svg"

export const ORDER_STATUSES = {
  PROCESSING: "обробляється",
  PROCESSING_PROBLEMATIC: "обробляється (проблемне)",
  GOING_TO: "збирається",
  COLLECTED: "зібрано",
  TRANSFERRED_NP: "передано НП",
  WAITING_AT_THE_POINT: "очікує в точці видачі",
  RECEIVED: "отримано",
  CANCELLED: "скасовано",
  RETURN: "повернення",
  PROBLEMATIC: "проблемне",
  IN_QUEUE: "в черзі",
  WAITING_DOWNLOAD: "очікує відвантаження",
  REFUSAL: "відмова",
  // "": "preorder",
  "": "не враховувати"
}

export const PAYMENT_STATUSES = {
  SUCCESSFUL: "успішно",
  SUCCESSFUL_HOLD: "успішно (hold)",
  PARTIAL_CANCELLATION: "часткове скасування",
  PAYMENT_ERROR: "помилка оплати",
  RETURNED: "повернуто",
  AWAITING_RETURN: "очікує на повернення",
  AWAITING_CONFIRMATION: "очікує на підтвердження адміном",
  REJECTED: "відхилено"
}

export const INNER_STATUS_CODES = {
  PROCESSING: {
    title: "обробляється",
    icon: iconReset,
    style: { background: "#C6E6D6" },
    cancel: true
  },
  PROCESSING_PROBLEMATIC: {
    title: "обробляється",
    icon: iconResetWhite,
    style: { background: "#FC536A", color: "#fff" },
    cancel: true
  },
  IN_QUEUE: {
    title: "в черзі",
    icon: iconQueue,
    style: { background: "#B9E1F4" },
    cancel: true
  },
  GOING_TO: {
    title: "збирається",
    icon: iconBag,
    style: { background: "#D7E2F3" },
    cancel: true
  },
  COLLECTED: {
    title: "зібрано",
    icon: iconBagFull,
    style: { background: "#D7E2F3" },
    cancel: true
  },
  PROBLEMATIC: {
    title: "проблемне",
    icon: iconExclamationMark,
    style: { background: "#FC536A", color: "#fff" },
    cancel: true
  },
  WAITING_DOWNLOAD: {
    title: "очікує відвантаження",
    icon: iconClock,
    style: { background: "#DFD8F9" },
    cancel: true
  },
  TRANSFERED_NP: {
    title: "передано НП",
    icon: iconDelivery,
    style: { background: "#D7E2F3" },
    cancel: true
  },
  WAITING_AT_THE_POINT: {
    title: "очікує в точці видачі",
    icon: iconTrue,
    style: { background: "#C6E6D6" },
    cancel: true
  },
  RECEIVED: {
    title: "отримано",
    icon: iconTrue,
    style: { background: "#C6E6D6" },
    cancel: false
  },
  RETURN: {
    title: "повернення",
    icon: iconFalse,
    style: { background: "#FFD5A6" },
    cancel: false
  },
  REFUSAL: {
    title: "відмова",
    icon: iconFalse,
    style: {
      background: "linear-gradient(90deg, #F69090 0%, #F6B5BF 100%)"
    },
    cancel: false
  },
  CANCELLED: {
    title: "скасовано",
    icon: iconFalse,
    style: {
      background: "linear-gradient(90deg, #F69090 0%, #F6B5BF 100%)"
    },
    cancel: false
  },
  PREORDER: {
    cancel: true,
    title: "створене"
  }
}

export const NP_STATUSES = {
  "np_status:4": "Відправлення у місті Вінниця",
  "np_status:6": "Відправлення у місті одержувача",
  "np_status:8": "Відправлення завантажено в Поштомат",
  "np_status:9": "Відправлення отримано",
  "np_status:102": "Відмова від отримання"
}
